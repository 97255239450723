export default {
  general: {
    hello: 'Hello',
    back: 'Back',
    left: 'Left',
    right: 'Right',
    both: 'Both',
    side: 'Side leg position',
    flank: 'Side',
    backside: 'Rear leg position',
    height: 'Height',
    depth: 'Depth',
    thickness: 'Thickness',
    standard: 'Standard',
    position: 'Position',
    material: 'Material',
    material_type: 'Material type',
    clothe: 'Covering of orthoses',
    type: 'Type',
    search: 'Search',
    cancel: 'Cancel',
    send: 'Send',
    ok: 'OK',
    continue_anyway: 'Continue anyway',
    update: 'An update is available.',
    install_update: 'Update',
    user: 'User',
    products: 'Products',
    client_info: 'Client data',
    order: 'Order',
    edit: 'Edit',
    coming_soon: 'Coming soon',
    add_file: 'Add file',
    salutation_mr: 'Mr.',
    salutation_mrs: 'Mrs.',
    terms_and_conditions: 'Terms and Conditions',
    product_terms: 'Product terms',
    faq: 'FAQ',
    not_available: 'Temporarily unavailable',
    updated_color: 'Updated color',
  },
  error: {
    header_api: 'Error communicating with API',
  },
  account: {
    customer_portal: 'Customer portal',
    title: 'Account',
    login: 'Login',
    signout: 'Sign out',
    username: 'Username',
    password: 'Password',
    forgotpassword: 'Forgot password',
    logged_in: 'You\'re logged in',
    error: {
      title: 'Invalid login',
      not_found: 'Invalid username/password combination',
    },
  },
  orders: {
    title: 'Order menu',
    synchronise: 'Synchronise orders',
    history: 'Order history',
    archive: 'Archived orders',
    custom: 'Custom',
    history_compatibility_header: 'Order not compatible',
    history_compatibility_message: 'This order is not compatible with this app version. Check whether the order is correct.',
    no_results: 'no orders found',
  },
  language: {
    title: 'Change language',
    dutch: 'Nederlands',
    german: 'Deutsch',
    english: 'English',
  },
  form: {
    choose_option: 'Choose option',
    not_available: 'None',
    input_placeholder: 'Type...',
    type: 'Type',
    number: 'Number',
    errors_heading: 'Form not complete yet',
    errors_text: 'A number of fields have not been filled out.',
    success_heading: 'Form is valid',
    success_text: 'Ready to submit your order.',
    save: 'Save',
    select_ok: 'OK',
    select_cancel: 'Cancel',
    undeliverable: '{field} is not available',
  },
  order: {
    shafts: 'Upper',
    existing_model: 'Existing model',
    select_image: 'Select image',
    remove_shoe_image: 'Remove shoe image',
    color: 'Colour',
    all: 'All',
    amount: 'Quantity',
    variant: 'Type',
    leather_no: 'Leather {no}',
    decoration: 'Decoration',
    perforation: 'Perforation',
    complexity_price: 'Provide a clear image in color',
    embroidery: 'Embroidery',
    lasering: 'Lasering',
    lining: 'Lining',
    vamp_lining: 'Vamp lining',
    offset: 'Position',
    heelpart_lining: 'Heelpart lining',
    tongue_label: 'Tongue label',
    sockshoe: 'Execute as sock shoe',
    sockshoe_description: 'Base with Ercoflex insole and rough leather outsole.',
    lining_vamp_no_stitches: 'No stitches in lining vamp',
    antislip_heel: 'Anti slip',
    liner_shoe: 'Lining shoe',
    vamp_point: 'Entry',
    main_stitches: 'Main stitches',
    decoration_stitching: 'Decoration stitching',
    quarter_opening: 'Quarter opening',
    choose_quarter_opening: 'Choose Quarter opening',
    stitching_yarn: 'Yarn',
    fastening: 'Fastening {no}',
    upper_height: 'Upper height',
    shaft_height_incl_heel: 'Upper height includes heel height',
    leg_measurements: 'Leg measurements',
    padded_shaft: 'Padding upper',
    polster: 'Padding',
    polster_upper: 'Upper padding',
    polster_upper_left: 'Upper padding left',
    polster_upper_right: 'Upper padding right',
    polstering: 'Polstering',
    polstering_material: 'Padding material',
    polster_tongue: 'Padding tongue',
    heelcollar_polster: 'Heel collar padding',
    heelcollar_polster_type: 'Heel collar padding type',
    tongue: 'Tongue',
    fixation_tongue: 'Tongue',
    supplement_type: 'Insoles type',
    supplement_stiffening: 'Stiffening',
    supplement_thickness: 'Insole thickness',
    detailed_supplement_thickness: 'Detailed insole thickness',
    supplement_retaining_wall_height: 'Retaining wall height',
    supplement_amenity_height: 'Orthoses height',
    detail_drawing: 'Working drawing',
    thickness_left: 'Thickness left',
    thickness_right: 'Thickness right',
    thickness_front: 'Thickness front',
    bottoming: 'Bottoming',
    manufacture_method: 'Production method',
    manufacture_method_note: 'Faster delivery time Type A when milled',
    manufacture_method_layer: '(Upper layer - to the sole of the foot)',
    supplement_remark: 'Insole remarks',
    fillsole: 'Space sole',
    innersole: 'Innersole',
    forefoot_prosthesis: 'Forefoot prosthesis (toe amputation)',
    sole_stiffner: 'Solestiffening',
    nose_material: 'Toe material',
    sole_supply_separately: 'Supply sole separately',
    nose_length: 'Nose length',
    counter_stiffening: 'Counter stiffening',
    heel_stiffener: 'Reinforcement',
    heel_stiffener_variant: 'Reinforcement type',
    heel_stiffener_height: 'Reinforcement height',
    last_annotation: 'Last annotation',
    garnish: 'Leather reinforcement',
    shaft_up_to_and_including_pins: 'Uppers until shaping',
    shaft_up_to_supply_material: 'Supply bottoming material',
    modeling_extension: 'Modeling extension',
    boarding: 'Boarding',
    boarding_front: 'Front',
    boarding_back: 'Back',
    boarding_front_back: 'Front and back',
    welt: 'Welt',
    all_welts: 'All welts',
    mid_sole: 'Midsole',
    no_foot_strike: 'Note: no foot strike',
    heel_type: 'Heel type',
    heel_position: 'Heel position',
    heel_position_supplement: 'Height (mm) under Inlay',
    heel_position_bottoming: 'Height (mm) in Bottoming',
    heel_color: 'Heelcolor',
    primary_color: 'Main color heel',
    secondary_color: 'Second color heel',
    heel_shore: 'Shorevalue',
    heel_height: 'Heel height',
    heel_width: 'Heel front width',
    copy_left: 'Copy left',
    heel_rolling: 'Heel rounding',
    heel_buffer: 'Heelbuffer',
    heel_expansion: 'Heel flare / tapered',
    medial: 'Medial',
    lateral: 'Lateral',
    rolling: 'Rocker',
    rolling_direction: 'Direction',
    rolling_side: 'Rocker {side}',
    rolling_type: 'Rocker type',
    rolling_width: 'Rocker width',
    thickness_toe: 'Thickness toes',
    thickness_ball: 'Sole bone thickness',
    thickness_heel: 'Thickness heel',
    thickness_ball_lateral: 'MTP 5 thickness',
    thickness_ball_medial: 'MTP 1 thickness',
    rocker_wrapped_in_boarding: 'Rocker wrapped in boarding',
    sole: 'Outsole',
    toplift: 'Top lift',
    finishing: 'Finishing',
    cover: 'Cover',
    cover_material: 'Cover material',
    polster_material: 'Polster material',
    lasting: 'Remove lasts',
    executor: 'Execute by',
    laces: 'Laces',
    finish_supplement: 'Finishing supplements',
    place_cover: 'Place cover',
    padding_layer: 'Layer of polster',
    shaft_remark: 'Remarks upper',
    bottoming_remark: 'Remarks Bottoming',
    info: 'Order information',
    salutation: 'Salutation',
    attachments: 'Attachments',
    last_no: 'Lastnumber',
    last_note: 'Indicate lastnumber on top of last',
    customer_name: 'Customer name',
    confirm_leave_header: 'Cancel changes?',
    confirm_leave_message: 'Are you sure you want to leave the order page? Any entered data will not be saved.',
    sent_toast: 'Order is sent',
    number: 'Order',
    date: 'Date',
    draft: 'Offer',
    sale: 'Saleorder',
    sent: 'Offer sent',
    done: 'Blocked',
    cancel: 'Canceled',
    reorder: 'Reorder',
    edit: 'Edit',
    form: 'Form',
    velcro_extra_length: 'Velcro\'s extra long',
    last: 'Last',
    has_last: 'Has last',
    supplements: 'Insoles',
    foil_shoe: 'Plastic fitting shoes',
    foil_shoe_height: 'Height',
    foil_shoe_remark: 'Plastic fitting shoes remarks',
    copy_existing_last: 'Copy existing last',
    deliver: 'Deliver',
    deliver_note: 'Which documents do you send or add?',
    cast: 'Synthetic casts',
    blueprint: 'Blueprints',
    scan: 'Lutracad',
    modeled_file: 'Modified file',
    scan_upload: 'Scan (own file)',
    last_type: 'Type last',
    last_same_length: 'Last same length',
    encore: 'Extra length toe box',
    encore_division: 'Lastlength {side}',
    leg_position: 'Leg position',
    fabric_edge: 'Sharp edge at heel',
    nose_shape: 'Toe shape',
    last_remark: 'Lasts remarks',
    sizing: 'sizing',
    leg_sizes: 'Leg measurements',
    height_measurement: 'Height measurements',
    circumferences: 'Foot measurements',
    circumferences_no: 'Circumferences foot {no}',
    foot_measurements: 'Corrections planter surface feet',
    position_toes: 'Position of toes',
    height_toes: 'Height',
    depth_toes: 'Sink',
    mtp_joints: 'MTP joints',
    depth_position: 'MTP {no}',
    lateral_and_medial_gel: 'Pressure under lateral and Medial footarch',
    medial_gel: 'Pressure under medial footarch',
    lateral_gel: 'Pressure under lateral footarch',
    imprint_base_back: 'Imprint back Base V',
    forefoot_support: 'MTP support',
    forefoot_support_instructions: 'Sign off on supplement',
    t_pad: 'T-pelotte',
    transversal: 'Transversal support',
    forefoot_and_heel_bone: 'Pronation and Suppination',
    pronation_front: 'Pronation front',
    pronation_back: 'Pronation back',
    suppination_front: 'Suppination front',
    suppination_back: 'Suppination back',
    lasts_supplements_foils: 'Lasts, insoles and plastic fitting shoes',
    finish: 'Finish',
    classics: 'Classics',
    flea: 'Post oparative shoe',
    orthotics: 'Orthotics',
    functional: 'Functional',
    heel_instep: 'Heel/entry',
    high_instep: 'High entry',
    low_instep: 'Low entry',
    ball: 'Ball',
    toe_jump: 'Toe spring',
    heel_lift: 'Heel spring',
    heel_thickness: 'Thickness heel',
    toe_no: 'Toe {no}',
    dimensions: 'Sizes',
    order_status: 'Order status',
    confirm_osa_header: 'Maximum number of OSA\'s in cart',
    confirm_osa_message: 'Would you like to empty the cart and start a new order?',
    confirm_osa_button_ok: 'Empty cart',
    leg_cover: 'Leg cover',
    trial_shoe: 'Trial shoe',
    bath_shoe: 'bath shoe',
    slipper: 'Slipper',
    basics: 'Basics',
    sock_shoe: 'Sock shoe',
    kind: 'Kind',
    changed_color_remark: '{color} has a updated color',
  },
  cart: {
    title: 'Cart',
    order: 'You order for',
    empty: 'Your cart is empty',
    added_header: 'Product added',
    added_subHeader: 'for {customer}',
    added_text: 'Would you like to order another product for this customer?',
    added_continue_shopping: 'Order another product',
    add_product: 'Add product',
    added_finish_order: 'To cart',
    last_supplement_or_foil: 'Last, insole or foil',
    finish: 'Finish',
    custom: 'Custom',
    send_header: 'Send order?',
    send_text: 'Please note: check carefully whether the order has been filled in correctly.',
    send_ok: 'Yes, send order',
    submitting_order: 'Processing order',
    submitting_line: 'Processing line {index} of {total}',
    submitting_attachment: 'Processing attachment {index} of {total}',
    new_order: 'New order',
    confirm_empty_header: 'Are you sure you want to remove the client data and empty the cart?',
    confirm_empty_button_ok: 'Start over',
    edit_client_info: 'Edit client data',
    production_order: 'Drag products to adjust the production order.\nThe order is processed from top to bottom.',
    verify_order: 'Verify all order lines via the cogwheel icon.',
    verify_order_label: 'Order line verification',
  },
};
